import React from "react";
import mainlogo from "../assets/images/mainlogo.png";
import call from "../assets/images/call.png";

const Termsconditions = () => {
    return (
        <>
         <section className="myheader">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-6 col-12">
                        <div className="mylogo">
                            <a href="/">
                            <h3 className="mynewlogo">Claims Car Insurance</h3>
                                </a>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-md-6 col-12">
                            <div className="calling">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img src={call} className="chatmsg" alt="" />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Call Us Now</h3>
                                        <p><a href="tel:08006546530">08006546530</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="privacy-policy">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="headw">
                                <h2>Terms & Conditions</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="main-content">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                           <h4>Terms of use </h4>
                         <p>Welcome to Claims Car Insurance (claims-car-insurance.co.uk). By accessing and using our website, you agree to be bound by these Terms and Conditions. Please read them carefully before using our services.</p>
                         <h4>1. Acceptance of Terms</h4>
                         <p>By using this website, you agree to comply with and be legally bound by the terms outlined below. If you do not agree to these terms, please discontinue your use of the website</p>
                         <h4>2. Use of the Website</h4>
                         <p>You agree to use the website only for lawful purposes and in accordance with these terms. You must not:</p>
                        <p>You agree to use the website only for lawful purposes and in accordance with these terms. You must not:</p>
                        <ul>
                            <li>Violate any applicable local, national, or international laws or regulations.</li>
                            <li>Transmit any unsolicited or unauthorized advertising, promotional materials, or spam.</li>
                            <li>Attempt to gain unauthorized access to the website, its servers, or any associated databases.</li>
                            <li>Use the website in any way that could damage, disable, or impair its functionality or interfere with another user's ability to access it.</li>
                        </ul>
                        <h4>3. Intellectual Property</h4>
                        <p>All content, materials, trademarks, logos, and other intellectual property displayed on this website are the property of Claims Car Insurance or licensed to us. You are not permitted to reproduce, distribute, modify, or create derivative works from any content on this website without our prior written consent.</p>
                        <h4>4. Disclaimer of Warranties</h4>
                        <p>The information and services provided on this website are for general informational purposes only. While we strive to keep the information accurate and up to date, Claims Car Insurance makes no warranties or representations about the accuracy, completeness, or reliability of the content. The website and its services are provided on an “as is” and “as available” basis without any warranties, express or implied.</p>
                        <h4>5. Limitation of Liability</h4>
                        <p>Claims Car Insurance will not be liable for any direct, indirect, incidental, or consequential damages that arise out of or are related to your use of, or inability to use, our website, including but not limited to:</p>
                        <ul>
                            <li>Loss of profits, revenue, or data.</li>
                            <li>Losses caused by viruses, bugs, or other harmful components obtained through your use of our website.</li>
                            <li>Any unauthorized access to your personal information, as outlined in our Privacy Policy.</li>
                        </ul>
                        <p>In no event shall Claims Car Insurance's total liability to you exceed the amount paid by you, if any, to use this website or its services.</p>
                        <h4>6. Third-Party Links</h4>
                        <p>Our website may contain links to third-party websites or services that are not owned or controlled by Claims Car Insurance. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites. Accessing these third-party links is at your own risk.</p>
                        <h4>7. User-Generated Content</h4>
                        <p>If you submit or post content to this website, including reviews, comments, or other forms of user-generated content, you grant Claims Car Insurance a non-exclusive, royalty-free, perpetual, and worldwide license to use, modify, reproduce, distribute, and display such content. We reserve the right to remove any content that violates these terms or is otherwise deemed inappropriate.</p>
                        <h4>8. Governing Law and Jurisdiction</h4>
                        <p>These Terms and Conditions are governed by and construed in accordance with the laws of the United Kingdom. You agree that any disputes arising out of or relating to the use of this website will be subject to the exclusive jurisdiction of the courts located in the United Kingdom.</p>
                        <h4>9. Changes to the Terms and Conditions</h4>
                        <p>Claims Car Insurance reserves the right to modify these Terms and Conditions at any time. Any changes will be posted on this page with an updated effective date. By continuing to use the website after such changes, you agree to the revised terms.</p>
                        <h4>10. Termination</h4>
                        <p>We reserve the right to terminate or restrict your access to the website, without notice, if we believe you have violated these Terms and Conditions or applicable law.</p>
                        <h4>Contact us </h4>
                            <p>If you have any questions about these Terms and Conditions, please contact us at: <a href="mailto:info@claims-car-insurance.co.uk">info@claims-car-insurance.co.uk.</a> </p>
                            <p>Phone: <a href="tel:0800 654 6530">0800 654 6530</a></p>
                            <p>Website: <a href="https://claims-car-insurance.co.uk/">claims-car-insurance.co.uk</a></p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="home2-copy-right">
    <div className="container">
        <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
                <ul>
                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                    <li><a href="/terms-conditions">Terms & Conditions</a></li>
                    <li><a href="/Contact">Contact Us</a></li>
                </ul>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
            <p className="home2-colpyy">
             © Copyright 2024 <a href="/">claims-car-insurance.co.uk</a>. All Rights Reserved.
              </p>
            </div>
        </div>
    </div>
</section>
            {/* <section className="copy-right01">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <p className="colpyy">
                            © Copyright 2024 <a href="/">Insurance claims online</a>. All Rights Reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    );
};

export default Termsconditions;
