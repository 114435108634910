import React from "react";
import mainlogo from "../assets/images/mainlogo.png";
import call from "../assets/images/call.png";

const Privacypolicy = () => {
    return (
        <>
            <section className="myheader">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-6 col-12">
                            <div className="mylogo">
                                <a href="/">
                                    <h3 className="mynewlogo">Claims Car Insurance</h3>
                                </a>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-md-6 col-12">
                            <div className="calling">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img src={call} className="chatmsg" alt="" />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Call Us Now</h3>
                                        <p><a href="tel:08006546530">08006546530</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="privacy-policy">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="headw">
                                <h2>Privacy Policy</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="main-content">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h4>Privacy Policy  </h4>
                            <p>At Claims Car Insurance (claims-car-insurance.co.uk), we value your privacy and are committed to protecting your personal information. This privacy policy outlines how we collect, use, disclose, and safeguard your data when you use our services.</p>
                            <h4>1. Information We Collect</h4>
                            <p>We may collect the following types of personal information:</p>
                            <ul>
                                <li><b>Personal Identification Data:</b> Name, contact details (email address, phone number), address, and any other information necessary for processing your claim.</li>
                                <li><b>Claim-Related Data:</b> Information related to your car insurance policy, accident details, vehicle details, and claim history.</li>
                                <li><b>Technical Data:</b> IP address, browser type, operating system, and browsing behaviour on our website.</li>
                            </ul>
                            <h4>2. How We Use Your Information</h4>
                            <p>We use your personal data for the following purposes:</p>
                            <ul>
                                <li>To process your car insurance claims.</li>
                                <li>To communicate with you regarding your claim status.</li>
                                <li>To improve our services and provide customer support.</li>
                                <li>To comply with legal and regulatory requirements.</li>
                                <li>To send you updates, offers, and relevant information related to car insurance, only if you have opted in.</li>
                            </ul>
                            <h4>3. Data Sharing and Disclosure</h4>
                            <p>We do not sell or rent your personal data to third parties. However, we may share your information with:</p>
                            <ul>
                                <li><b>Insurance Companies:</b> To process your claim and verify the details of your policy.</li>
                                <li><b>Legal and Regulatory Authorities: </b>As required by law or to protect our rights.</li>
                                <li><b>Service Providers:</b> Third-party vendors who assist in claim processing or providing technical support to our website.</li>
                            </ul>
                            <h4>4. Data Retention</h4>
                            <p>We retain your personal information only for as long as necessary to fulfil the purposes for which it was collected, including legal, regulatory, and business purposes.</p>
                            <h4>5. Data Security</h4>
                            <p>We implement reasonable security measures to protect your personal data from unauthorised access, disclosure, alteration, or destruction. However, no internet-based service is completely secure, and we cannot guarantee absolute security.</p>
                            <h4>6. Your Rights</h4>
                            <p>You have the right to:</p>
                            <ul>
                                <li>Access and request a copy of the personal data we hold about you.</li>
                                <li>Request corrections to any inaccurate or incomplete data.</li>
                                <li>Request the deletion of your personal data, subject to legal limitations.</li>
                                <li>Withdraw your consent to data processing at any time, where consent is required.</li>
                            </ul>
                            To exercise these rights, please contact us at <a href="mailto:info@claims-car-insurance.co.uk">info@claims-car-insurance.co.uk.</a>
                            <h4>7. Cookies and Tracking Technologies</h4>
                            <p>We may use cookies and similar technologies to enhance your experience on our website and collect technical data about your browsing behaviour. You can control the use of cookies through your browser settings.</p>
                            <h4>8. Changes to this Privacy Policy</h4>
                            <p>We may update this privacy policy from time to time. Any changes will be posted on this page with an updated effective date. Please review this policy periodically.</p>
                            <h4>9. Contact Us</h4>
                            <p>If you have any questions or concerns regarding this privacy policy, please contact us at:  <a href="mailto:info@claims-car-insurance.co.uk">info@claims-car-insurance.co.uk.</a> </p>
                            <p>Phone: <a href="tel:0800 654 6530">0800 654 6530</a></p>
                            <p>Website: <a href="https://claims-car-insurance.co.uk/">claims-car-insurance.co.uk</a></p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="home2-copy-right">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
                            <ul>
                                <li><a href="/privacy-policy">Privacy Policy</a></li>
                                <li><a href="/terms-conditions">Terms & Conditions</a></li>
                                <li><a href="/Contact">Contact Us</a></li>
                            </ul>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
                            <p className="home2-colpyy">
                                © Copyright 2024 <a href="/">claims-car-insurance.co.uk</a>. All Rights Reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="copy-right01">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <p className="colpyy">
                            © Copyright 2024 <a href="/">Insurance claims online</a>. All Rights Reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    );
};

export default Privacypolicy;
