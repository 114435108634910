import React from "react";
import mainlogo from "../assets/images/mainlogo.png";
import call2 from "../assets/images/call2.png";
import call from "../assets/images/call.png";
import girl from "../assets/images/girl.png";
import boy from "../assets/images/boy.png";

const Contactus = () => {
    return (
        <>

         <section className="home2">
                <div className="container">
                    <div className="row" id="home-header">
                        <div className="col-xxl-7 col-xl-7 col-md-6 col-12">
                            <div className="home2-mylogo">
                                <a href="/">
                                    <h3>Insurance Claim Online</h3>
                                    {/* <img src={mainlogo} className="mainlogo" alt="logo" /> */}
                                </a>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-md-6 col-12">
                            <div className="calling">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img src={call2} className="chatmsg" alt="" />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Call Us Now</h3>
                                        <p><a href="tel:0333 000 000">0333 000 000</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-center" id="home2-content">
                        <div className="col-xxl-12 col-md-12 col-12" >
                            <div className="home2-content-box">
                                <h1>Road accident claims management </h1>
                                <p className="accident">Make a successful claim with the guidance of our UK-based no-fault claim experts. </p>
                                <h3 className="hr-lines">Get In Touch With Us</h3>
                                <div className="Dedicated">
                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                                </div>
                                
                                <div className="home2-settlement">
                                <div className="settlement">
                                    <div className="cont-form" id="contv">
                                        <form action="Service.php" method="post" enctype="multipart/form-data">
                                        <div className="row">
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                                            <input type="text" name="name" className="form-control" placeholder="Name" required />
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                                            <input type="email" name="email" className="form-control" placeholder="Email" required />
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                                            <input type="tel" name="phone" className="form-control" placeholder="Phone" required />
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                                            <input type="text" name="subject" className="form-control" placeholder="Subject" required />
                                         
                                            </div>
                                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                                            <textarea id="message" title="Message"  name='message'  placeholder="How can we help you?" required />
                                            <div className="form-btn">
                                            <input type="submit" className="form-rol" defaultValue="send" />
                                            </div>
                                            </div>
                                        </div>
                                            
                                        </form>
                                    </div>
                                </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>

            </section>
<section className="home2-copy-right">
    <div className="container">
        <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
                <ul>
                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                    <li><a href="/terms-conditions">Terms & Conditions</a></li>
                    <li><a href="/contactus">Contact Us</a></li>
                </ul>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
            <p className="home2-colpyy">
             © Copyright 2024 <a href="/">Insurance claims online</a>. All Rights Reserved.
              </p>
            </div>
        </div>
    </div>
</section>
        </>
    );
};

export default Contactus;
